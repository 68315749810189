import joinClasses from "helpers/joinClasses";

export function Card({ children, className, ...props }) {
  return (
    <div
      className={joinClasses(
        "min-w-[90vw] sm:min-w-[500px] md:min-w-[744px] max-w-[744px] w-full md:max-h-[600px] bg-primary rounded-[20px] px-8 md:px-16 py-10 transition-all duration-300 ease-in-out",
        "container",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
