import { Card } from "components/atoms";
import joinClasses from "helpers/joinClasses";

import questions from "utils/questions";

export function QuestionTemplate({
  onNext = () => {},
  active = false,
  question = {},
  position = 1,
  total = questions.length,
  children,
}) {
  if (!question) {
    return null;
  }

  return (
    <div
      className={joinClasses(
        "flex justify-between w-full w-full",
        !active && "hidden"
      )}
    >
      {/* image left pour les questions */}
      <div
        className="w-full bg-no-repeat bg-center md:bg-center"
        style={{
          backgroundImage: `url("/assets/questions/${question.images.left}")`,
        }}
      />
      <Card className="mt-16 z-10 relative pb-16 !bg-[rgba(30,108,177,0.1)]">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-end gap-3">
          <p className="font-proximanova-alt-light text-[25px] leading-[30px]">
            <span className="font-proximanova-extrabold text-[60px] text-fourth">
              {position < 10 ? `0${position}` : position}
            </span>
            /{total < 10 ? `0${total}` : total}
          </p>

          {question.label && (
            <p className="bg-secondary font-proximanova-extrabold text-white md:-mr-16 pr-8 pl-5 py-2 text-[15px] tracking-[5px] uppercase">
              {question.label}
            </p>
          )}
        </div>
        {question?.bonus && (
          <p className="mt-6 text-secondary font-black tracking-[3px] text-[15px]">
            QUESTION BONUS
          </p>
        )}
        <h2 className="font-proximanova-bold text-[20px] mt-5 mb-7">
          {question.title}
        </h2>
        <hr className="border-fourth" />
        {children}
      </Card>
      <div
        className="w-full bg-no-repeat bg-center md:bg-right md:mr-[-50px]"
        style={{
          backgroundImage: `url("/assets/questions/${question.images.right}")`,
        }}
      />
    </div>
  );
}
