import { Card, SecondaryButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Register({ onNext = () => { }, show = false }) {
  const [isAwnserd, setIsAwnserd] = useState(false);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    home_site: "",
    rules: false,
  });

  const entries = {
    firstname: "entry.894840064",
    lastname: "entry.23773172",
    home_site: "entry.1593551963",
  };

  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  useEffect(() => {
    if (
      user.firstname.length >= 1 &&
      user.lastname.length >= 2 &&
      user.home_site.length >= 2 &&
      user.rules
    ) {
      setIsAwnserd(true);
    } else {
      setIsAwnserd(false);
    }
  }, [user]);

  return (
    <div
      className={joinClasses(
        "flex justify-between w-full w-full",
        show ? "block" : "hidden"
      )}
    >
      <div
        className="w-full bg-no-repeat bg-center"
        style={{
          backgroundImage: `url("/assets/icons/welcome2-left.svg")`,
        }}
      />
      <Card className="mt-16 z-10 relative">
        <h2 className="font-raleway-black text-left text-[25px] text-fourth uppercase mt-8 mb-5">
          Informations personnelles
        </h2>
        <div className="flex flex-col md:flex-row md:gap-5">
          <input
            type="text"
            name={entries.lastname}
            onChange={(event) =>
              setUser({ ...user, lastname: event.target.value })
            }
            placeholder="Nom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
          />
          <input
            type="text"
            name={entries.firstname}
            onChange={(event) =>
              setUser({ ...user, firstname: event.target.value })
            }
            placeholder="Prénom"
            className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
          />
        </div>
        <select
          className="w-full border border-gray-300 rounded-md p-3 mb-5 focus:outline-primary focus:border-primary"
          onChange={(event) =>
            setUser({ ...user, home_site: event.target.value })
          }
          defaultValue=""
          name={entries.home_site}
        >
          <option disabled value="">
            Site de rattachement
          </option>
          <option value="ARRAS">ARRAS</option>
          <option value="BESANCON">BESANCON</option>
          <option value="BEZIERS">BEZIERS</option>
          <option value="BORDEAUX">BORDEAUX</option>
          <option value="BRIE COMTE ROBERT">BRIE COMTE ROBERT</option>
          <option value="BRIGNOLES">BRIGNOLES</option>
          <option value="CAEN">CAEN</option>
          <option value="CHAMBERY">CHAMBERY</option>
          <option value="CHILLY">CHILLY</option>
          <option value="CLERMONT">CLERMONT</option>
          <option value="COMBS-LA-VILLE">COMBS-LA-VILLE</option>
          <option value="COURTHEZON">COURTHEZON</option>
          <option value="CRETEIL">CRETEIL</option>
          <option value="DIJON">DIJON</option>
          <option value="FLASSANS">FLASSANS</option>
          <option value="GRENOBLE">GRENOBLE</option>
          <option value="IVRY">IVRY</option>
          <option value="LES PENNES MIRABEAU">LES PENNES MIRABEAU</option>
          <option value="LILLE">LILLE</option>
          <option value="LIMOGES">LIMOGES</option>
          <option value="LOMME">LOMME</option>
          <option value="LYON">LYON</option>
          <option value="MARSEILLE">MARSEILLE</option>
          <option value="METZ">METZ</option>
          <option value="MITRY MORY">MITRY MORY</option>
          <option value="MONTPELLIER">MONTPELLIER</option>
          <option value="NANTES">NANTES</option>
          <option value="NICE">NICE</option>
          <option value="NIORT">NIORT</option>
          <option value="PLAISIR">PLAISIR</option>
          <option value="REIMS">REIMS</option>
          <option value="RENNES">RENNES</option>
          <option value="ROISSY">ROISSY</option>
          <option value="ROUEN">ROUEN</option>
          <option value="ST-OUEN-L'AUMONE">ST-OUEN-L'AUMONE</option>
          <option value="STRASBOURG">STRASBOURG</option>
          <option value="SUCY">SUCY</option>
          <option value="TOULOUSE">TOULOUSE</option>
          <option value="TOURS">TOURS</option>
        </select>

        <div>
          <input
            type="checkbox"
            name="cgu"
            id="cgu"
            className="mr-2"
            onChange={(event) =>
              setUser({ ...user, rules: event.target.checked })
            }
            required
          />
          <label htmlFor="cgu">
            J'accepte le{" "}
            <Link to="/reglement" className="text-fourth">
              règlement du jeu concours
            </Link>
          </label>
        </div>
        <div className="flex justify-center mt-16">
          {isAwnserd && (
            <SecondaryButton
              onClick={handleClick}
              className={joinClasses(
                !isAwnserd &&
                "!cursor-not-allowed pointer-events-none", "!bg-fourth"
              )}
            >
              Continuer {">"}
            </SecondaryButton>
          )}
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-center"
        style={{
          backgroundImage: `url("/assets/icons/welcome2-right.svg")`,
        }}
      />
    </div>
  );
}
