const questions = [
  {
    id: 1,
    name: "entry.400182990",
    type: "radio",
    title:
      "Qu'est-ce que la RQTH ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Un dispositif d'accompagnement pour les personnes handicapées.",
        value: "Un dispositif d'accompagnement pour les personnes handicapées.",
      },
      {
        label:
          "Une allocation financière accordée aux personnes handicapées.",
        value:
          "Une allocation financière accordée aux personnes handicapées.",
      },
      {
        label:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
        value:
          "Une reconnaissance administrative du statut de travailleur handicapé.",
      },
    ],
  },
  {
    id: 2,
    name: "entry.1042348623",
    type: "radio",
    title: "Que veut dire l'acronyme « RQTH » ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Recherche de Qualification pour Travailleurs Handicapés.",
        value: "Recherche de Qualification pour Travailleurs Handicapés.",
      },
      {
        label: "Reconnaissance de Qualité de Travailleur Handicapé.",
        value: "Reconnaissance de Qualité de Travailleur Handicapé.",
      },
      {
        label:
          "Rémunération pour Quotient de Travailleur Handicapé.",
        value:
          "Rémunération pour Quotient de Travailleur Handicapé.",
      },
    ],
  },
  {
    id: 3,
    name: "entry.757304031",
    type: "radio",
    title:
      "Qui peut bénéficier de la RQTH ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Uniquement les personnes ayant un handicap physique.",
        value: "Uniquement les personnes ayant un handicap physique.",
      },
      {
        label: "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
        value: "Uniquement les personnes qui ont besoin d'un aménagement de poste.",
      },
      {
        label: "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
        value: "Toutes les personnes ayant une situation de santé qui impacte leur quotidien de travail.",
      },
    ],
  },
  {
    id: 4,
    name: "entry.1937960583",
    type: "checkbox",
    title:
      "Quels sont les avantages de la RQTH pour les salariés ? ",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Aides ou prestations pour aménager la situation de travail et compenser le handicap (par exemple, le financement de prothèses auditives, l'achat d'un siège ergonomique...).",
        value: "Aides ou prestations pour aménager la situation de travail et compenser le handicap (par exemple, le financement de prothèses auditives, l'achat d'un siège ergonomique...).",
      },
      {
        label: "Possibilité d'avoir recours au CAP EMPLOI pour aider à la recherche de solutions (recherche d'emploi, formation, aménagements de poste).",
        value: "Possibilité d'avoir recours au CAP EMPLOI pour aider à la recherche de solutions (recherche d'emploi, formation, aménagements de poste).",
      },
      {
        label: "Bénéficier d'un suivi médical renforcé par le médecin du travail.",
        value: "Bénéficier d'un suivi médical renforcé par le médecin du travail.",
      },
      {
        label: "Aides pour sa réorientation et sa reconversion professionnelle quand l'ancien métier n'est plus possible (financement et appuis pour des bilans, des formations, des aides à la création d'entreprise...).",
        value: "Aides pour sa réorientation et sa reconversion professionnelle quand l'ancien métier n'est plus possible (financement et appuis pour des bilans, des formations, des aides à la création d'entreprise...).",
      },
    ],
  },
  {
    id: 5,
    name: "entry.1109527574",
    type: "radio",
    title: "Comment obtenir une RQTH ?",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "En faisant une demande à la Maison Départementale des Personnes Handicapées (MDPH).",
        value: "En faisant une demande à la Maison Départementale des Personnes Handicapées (MDPH).",
      },
      {
        label: "En faisant la demande auprès de la sécurité sociale.",
        value: "En faisant la demande auprès de la sécurité sociale.",
      },
      {
        label: "En faisant la demande à sa mutuelle.",
        value: "En faisant la demande à sa mutuelle.",
      },
    ],
  },
  {
    id: 6,
    name: "entry.1730232735",
    type: "radio",
    title: "La RQTH est le seul moyen de faire reconnaître sa situation de handicap au travail ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 7,
    name: "entry.1656040742",
    type: "radio",
    title:
      "En transmettant ma RQTH, mon employeur sera informé de la nature de ma pathologie. ",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 8,
    name: "entry.59470841",
    type: "radio",
    title:
      "Quel est l'objectif de la RQTH ?",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Reconnaître les compétences professionnelles des travailleurs handicapés.",
        value: "Reconnaître les compétences professionnelles des travailleurs handicapés.",
      },
      {
        label: "Faciliter l'accès à l'emploi des travailleurs handicapés.",
        value: "Faciliter l'accès à l'emploi des travailleurs handicapés.",
      },
      {
        label: "Avoir une compensation de revenu.",
        value: "Avoir une compensation de revenu.",
      },
    ],
  },
  {
    id: 9,
    name: "entry.1005424498",
    type: "radio",
    title:
      "Un salarié ayant une RQTH risque de se voir refuser un prêt bancaire ou d'augmenter sa cotisation de mutuelle ? ",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 10,
    name: "entry.781860223",
    type: "checkbox",
    title: "Un salarié qui souhaite savoir s'il est éligible à la RQTH et/ou qui souhaite se faire accompagner pour monter le dossier RQTH, à qui peut-il en parler ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "La mission Handicap.",
        value: "La mission Handicap.",
      },
      {
        label: "Le référent Handicap.",
        value: "Le référent Handicap.",
      },
      {
        label: "Le service RH.",
        value: "Le service RH.",
      },
      {
        label: "Son manager.",
        value: "Son manager.",
      },
      {
        label: "Le service de santé au travail.",
        value: "Le service de santé au travail.",
      },
    ],
  },
  {
    id: 11,
    name: "entry.2119469881",
    type: "radio",
    title:
      "Lorsqu'un salarié fait une RQTH, l'employeur est automatiquement informé, ainsi que toutes les administrations publiques. ",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 12,
    name: "entry.1629248619",
    type: "radio",
    title: "Lorsqu'une maladie est provisoire, on ne peut pas se faire reconnaître une RQTH.",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 13,
    name: "entry.1332747395",
    type: "radio",
    title:
      "En déclarant sa RQTH à son employeur, quelles peuvent être les conséquences : ",
    selectMax: 1,
    images: { left: "1-2.svg", right: "1-1.svg" },
    answers: [
      {
        label: "Perte de son emploi",
        value: "Perte de son emploi",
      },
      {
        label: "Une information auprès des collègues",
        value: "Une information auprès des collègues",
      },
      {
        label: "Prévention des risques pour conserver son emploi",
        value: "Prévention des risques pour conserver son emploi",
      },
      {
        label: "Changement de poste vers une voie de garage",
        value: "Changement de poste vers une voie de garage",
      },
      {
        label: "Il n'est pas nécessaire de compléter cette réponse",
        value: "Il n'est pas nécessaire de compléter cette réponse",
      },
    ],
  },
  {
    id: 14,
    name: "entry.1184826046",
    type: "radio",
    title:
      "Cas pratique : un salarié a une RQTH, il a besoin de prendre une pause plus longue, que fait-il ?",
    selectMax: 1,
    images: { left: "2-2.svg", right: "2-1.svg" },
    answers: [
      {
        label: "Il continue comme avant, en prenant une pause aussi longue que celle des autres",
        value: "Il continue comme avant, en prenant une pause aussi longue que celle des autres",
      },
      {
        label: "Il en parle à son manager ou son service RH",
        value: "Il en parle à son manager ou son service RH",
      },
      {
        label: "Il prend une pause plus longue sans en parler en amont, car la RQTH lui octroie le droit de s'adapter à son besoin",
        value: "Il prend une pause plus longue sans en parler en amont, car la RQTH lui octroie le droit de s'adapter à son besoin",
      },
    ],
  },
  {
    id: 15,
    name: "entry.77325295",
    type: "radio",
    title:
      "Un salarié dyslexique peut-il obtenir la RQTH ?",
    selectMax: 1,
    images: { left: "3-2.svg", right: "3-1.svg" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
  },
  {
    id: 16,
    name: "entry.95861815",
    type: "radio",
    title:
      "Il faut obligatoirement que le handicap se voie pour se faire reconnaître RQTH.",
    selectMax: 1,
    images: { left: "4-2.svg", right: "4-1.svg" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
];

export default questions;
